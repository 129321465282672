import { ComponentType, memo } from 'react';

import { Link } from '@/components/shared/element/link';
// import { Image } from '@/components/shared/media/image';
import type { ImageProps } from '@/components/shared/media/image/Image.props';
import { styled } from '@/stitches.config';

const PrimaryNavLogoLinkStyled = styled(Link, {
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  variants: {
    color: {
      white: {
        // filter: 'brightness(100)',
        color: '$white',
      },
      red: {
        color: '$primary',
      },
    },
  },
});

interface PrimaryNavLogoProps
  extends React.ComponentProps<typeof PrimaryNavLogoLinkStyled> {
  isTransparentNavigation?: boolean;
  isClickMenu?: boolean;
  onMouseEnter?: () => void;
  logo?: ImageProps;
  Logo?: ComponentType;
}

const PrimaryNavLogo: React.FC<PrimaryNavLogoProps> = memo(
  ({
    // logo,
    Logo,
    isTransparentNavigation,
    isClickMenu = false,
    onMouseEnter,
  }: PrimaryNavLogoProps) => {
    return (
      <PrimaryNavLogoLinkStyled
        href="/"
        onMouseEnter={onMouseEnter}
        tracking={{
          dataTrack: 'primary-nav',
          dataTrackText: 'logo',
          dataTrackUrl: '/',
        }}
        test={{
          dataTest: 'logo',
        }}
        color={!isTransparentNavigation || isClickMenu ? 'red' : 'white'}
      >
        {/* <Image
          priority={logo?.priority}
          loading={logo?.loading}
          src={logo?.src ?? ''}
          alt={logo?.alt ?? ''}
          width={logo?.width ?? 0}
          height={logo?.height ?? 0}
          layout="responsive"
          isMaxWidth
        /> */}
        <Logo />
      </PrimaryNavLogoLinkStyled>
    );
  }
);

PrimaryNavLogo.displayName = 'PrimaryNavLogo';

export default PrimaryNavLogo;
