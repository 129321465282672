export const IconCarLcv: React.FC = () => (
  <svg
    width="60"
    height="36"
    viewBox="0 0 60 36"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#444"
      d="M49.318 11.517h-.114c-2.33 0-4.204 1.9-4.204 4.26v.116c.057 2.361 1.931 4.204 4.26 4.204 2.33-.058 4.148-1.958 4.148-4.32 0-2.302-1.818-4.203-4.09-4.26Zm1.647 4.376c0 .979-.852 1.785-1.818 1.727-.966 0-1.761-.863-1.704-1.842v-.058c0-.979.795-1.785 1.76-1.785h.058c.965 0 1.76.864 1.704 1.843v.115Z"
    />
    <path
      fill="#444"
      d="m59.317 11.344-.114-1.267c0-.633 0-1.785.114-2.13.17-.519.17-1.383.17-1.44v-.115L56.59 2.418l.795-.403-.511-.23c-.057-.058-.966-.46-3.693-.806V.288h-.284C51.875.23 51.42.576 51.25.806 48.864.576 45.569.46 41.024.518c-9.6.345-11.191 1.21-11.305 1.324l-6.93 4.146-8.465.864c-.17 0-4.488.519-5.226 3.34v1.9l.113.116c.341.287.739.748.682.979-.057.288-.454.748-.682.92l-.113.116v.173c0 .057.284 1.727 1.59 1.958.739.115 1.477.115 2.216.057l.284-.057v-.288c0-.173-.17-4.895 4.942-5.24 1.193 0 2.33.403 3.238 1.209.91.864 1.477 2.13 1.705 3.743l10.055-.288c1.08 0 1.818.979 1.874 1.497l.057.576h8.294a1.26 1.26 0 0 0 .739-.46c.17-.231.284-.519.284-.807 0-1.555.454-5.24 4.658-5.355 4.602-.115 5.51 4.894 5.568 5.125l.056.288.284-.058c.512-.058 4.772-.576 5-2.649.056-.23.056-.46.056-.633v-.115c-.113-.98-.454-1.382-.681-1.555ZM35.23 6.392l-7.67.46-.056-1.958 3.124-1.727h.057c.057-.058.17-.058.34-.115 1.705-.519 4.034-.346 4.034-.346.17 0 .284.058.284.115l-.113 3.57Zm2.556-.173-.17-3.455c.056-.058.113-.058.227-.058h6.306c.51 0 .624.23.624.23l1.137 2.822-8.124.46Zm14.657-1.382c-.057.057-.398.46-1.364.576l-3.067.172-1.307-2.821c1.023.23 3.636.576 5.794 1.555a.786.786 0 0 1 .171.23c-.057.115-.114.173-.227.288Zm2.215-1.728 1.42-.403 1.875 2.591c-1.704-.403-3.181-1.957-3.295-2.188Z"
    />
    <path
      fill="#444"
      d="M50.74 29.044v-6.622l-16.134.403-.568-4.78c0-.46-.739-.863-1.08-.863l-12.781.403c-.398 0-1.364.23-1.762.691l-6.135 4.722-9.487.921c-.852.116-1.534.807-1.647 1.613 0 0-.796 3.973-.91 4.203a2.088 2.088 0 0 0 .796 2.765l2.159.345c-.114-.576-.114-1.152-.057-1.785.398-2.822 2.954-4.837 5.794-4.434 2.784.403 4.772 2.994 4.375 5.874h22.61c-.057-.404-.057-.749-.057-1.152.227-2.88 2.727-5.01 5.51-4.722a5.105 5.105 0 0 1 4.658 5.586 19.18 19.18 0 0 0 5.624-1.382v-1.785h-.908Zm-26.757-5.643-10.226.058 5.283-3.974c.284-.23.625-.345 1.023-.345l3.863-.115V23.4h.057Zm8.294-4.607.397 4.434-6.703.116v-4.377"
    />
    <path
      fill="#444"
      d="M41.08 27.663h-.113c-2.215 0-4.033 1.842-4.033 4.088s1.818 4.089 4.033 4.089c2.216 0 4.033-1.843 4.033-4.089 0-2.188-1.704-4.03-3.92-4.088Zm1.591 4.26a1.757 1.757 0 0 1-1.76 1.67c-.967-.057-1.648-.863-1.648-1.784v-.058c0-.979.738-1.727 1.704-1.727h.057c.966.057 1.647.806 1.647 1.785v.115Zm-34.368-4.26H8.19c-2.216 0-4.034 1.842-4.034 4.088v.115c.057 2.246 1.875 4.089 4.09 4.031 2.216-.057 4.034-1.9 3.977-4.146.057-2.188-1.704-4.03-3.92-4.088Zm1.59 4.203a1.65 1.65 0 0 1-1.76 1.67c-.966 0-1.704-.806-1.648-1.785v-.058c0-.978.739-1.727 1.705-1.727h.056c.966 0 1.705.806 1.648 1.785v.115Zm7.159-14.591c-.454-.288-.738-.864-.738-1.44v-.057c0-.98.795-1.785 1.76-1.785h.058c.965 0 1.76.864 1.704 1.842l2.443-.057a4.188 4.188 0 0 0-4.09-4.204h-.114c-2.33 0-4.204 1.9-4.204 4.261v.116c0 1.094.455 2.073 1.136 2.821l2.045-1.497Z"
    />
  </svg>
);
IconCarLcv.displayName = 'IconCarLcv';
