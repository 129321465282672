import React from 'react';

import Header from '@/components/layouts/header';
import { IconCarLcv } from '@/components/layouts/header/icon-car-lcv';
import { LogoIsuzuKingOfTrucks } from '@/components/layouts/header/logo-isuzu-king-of-trucks';
import {
  MENU_CV_BUYING_TOOLS,
  MENU_CV_PRIMARY_NAV,
  MENU_CV_SECONDARY_NAV,
} from '@/contents/link';

interface IProps {
  children?: React.ReactNode;
}

const View: React.FunctionComponent<IProps> = (props: IProps) => {
  return (
    <>
      <Header
        Logo={LogoIsuzuKingOfTrucks}
        logo={{
          src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltef13d9395e15071b/66bde4ce77be398f5cea664a/logo-isuzu-king-of-trucks.svg',
          alt: 'Isuzu King of Trucks',
          width: 137,
          height: 38,
        }}
        IconCar={IconCarLcv}
        menuBuyingTool={MENU_CV_BUYING_TOOLS}
        menuModel={MENU_CV_SECONDARY_NAV}
        menuMain={MENU_CV_PRIMARY_NAV}
      />
      {props.children}
    </>
  );
};
View.displayName = 'CVLayout';
export default View;
